<template>
    <modal ref="modalEditarArchivo" titulo="Editar nombre archivo" guardar tamano="modal-md" adicional="Guardar" @adicional="updateFile">
        <ValidationObserver ref="form">
            <div class="row mx-0">
                <div class="col-12">
                    <p class="f-13 ml-2">Nombre</p>
                    <ValidationProvider v-slot="{errors}" rules="required|max:60" name="Nombre">
                        <el-input v-model="name" placeholder="Nombre del archivo" size="small" :maxlength="60" />
                        <p class="text-danger f-10">{{ errors[0] }}</p>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>
<script>
import Service from '~/services/biblioteca/archivos';

export default {
    data(){
        return {
            id: null,
            name: null,
        }
    },

    methods:{
        toggle(data){
            this.id = data.id;
            this.name = data.name;
            this.$refs.form.reset();
            this.$refs.modalEditarArchivo.toggle();
        },

        async updateFile(){
            let valid = await this.$refs.form.validate();
			
            if(!valid){
                return;
            }

            const payload = {
                nombre: this.name,
            };

            const {data} = await Service.putFile(this.id, payload);
            if(data.success){
                this.$notify({
                    title: 'Mensaje',
                    message: data.mensaje,
                    type: 'success'
              	});
                this.$emit('update', {id: this.id, name:this.name, index: this.index});
                this.$refs.modalEditarArchivo.toggle();
            }
        },
    }
}
</script>
<style lang="scss" scoped>

</style>